<template>
    <div id="menubox">
        <div class="menubox_logo" @click="$router.push('home')">
            <img :src="require('../assets/svg/logo.svg')" />
        </div>
        <div class="menubox_navigation">
            <svg
                class="menubox_navigation_menu"
                @click="check"
                viewBox="0 0 50 50"
            >
                <g>
                    <line x1="17.25" y1="3.76" x2="46.27" y2="3.76" />
                    <line x1="17.25" y1="25" x2="46.27" y2="25" />
                    <line x1="17.25" y1="46.24" x2="46.27" y2="46.24" />
                    <line x1="3.73" y1="25" x2="3.73" y2="25" />
                    <polyline points="46.03,46.24 3.73,3.76 3.73,3.76" />
                    <polyline points="46.03,3.85 3.73,46.24 3.73,46.24" />
                </g>
            </svg>
            <div
                class="menubox_navigation_contact"
                @click="$router.push('contact')"
            >
                <p>contact</p>
                <div></div>
            </div>
        </div>
        <div class="menubox_menu" v-show="if_visible">
            <div class="menubox_menu_backg">
                <img :src="require('../assets/svg/logo house.svg')" />
                <img :src="require('../assets/svg/logo house.svg')" />
                <img :src="require('../assets/svg/logo house.svg')" />
                <img :src="require('../assets/svg/logo house.svg')" />
            </div>
            <div class="menubox_menu_selections">
                <div class="mms_selection" @click="$router.push('home')">
                    <p>home</p>
                    <p>主页</p>
                </div>
                <div class="mms_selection" @click="$router.push('videos')">
                    <p>videos</p>
                    <p>视频</p>
                </div>
                <div class="mms_selection" @click="$router.push('photos')">
                    <p>photos</p>
                    <p>图片</p>
                </div>
                <div class="mms_selection" @click="$router.push('contact')">
                    <p>contact</p>
                    <p>联系</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import gsap from "gsap";
export default {
    name: "menu",
    data() {
        return {
            _menubox_menu: {},
            _mms_selection: [],
            _menubox_menu_backg_img: [],
            _menubox_navigation_menu: {},
            if_visible: false,
            // 菜单是否在进行动画
            if_showing: false,
        };
    },
    methods: {
        show() {
            this.if_showing = true;
            this.if_visible = true;
            gsap.timeline()
                .to(this._menubox_menu, {
                    transform: "translateY(0)",
                    "border-top-left-radius": "0",
                    "border-top-right-radius": "0",
                    duration: 1,
                    ease: "power4.out",
                })
                .to(
                    this._mms_selection,
                    {
                        transform: "translateX(0)",
                        duration: 0.8,
                        ease: "power4.out",
                        stagger: 0.1,
                    },
                    "<"
                )
                .to(
                    this._menubox_menu_backg_img,
                    {
                        transform: "translateY(0)",
                        duration: 0.8,
                        ease: "power4.out",
                        stagger: 0.1,
                        onComplete: () => {
                            this.if_showing = false;
                        },
                    },
                    "<"
                );
        },
        hidden() {
            this.if_showing = true;
            gsap.timeline()
                .to(this._mms_selection, {
                    transform: "translateX(-100%)",
                    duration: 0.6,
                    ease: "power3.out",
                    stagger: 0.1,
                    onComplete: () => {
                        this.if_visible = false;
                        this.if_showing = false;
                    },
                })
                .to(
                    this._menubox_menu_backg_img,
                    {
                        transform: "translateY(100%)",
                        duration: 0.6,
                        ease: "power3.out",
                        stagger: 0.1,
                    },
                    "<"
                )
                .to(
                    this._menubox_menu,
                    {
                        transform: "translateY(100%)",
                        "border-top-left-radius": "10rem",
                        "border-top-right-radius": "10rem",
                        duration: 0.8,
                        ease: "power3.out",
                    },
                    "<0.3"
                );
        },
        reload() {
            this._menubox_navigation_menu.classList.remove(
                "menubox_navigation_menu_show"
            );
            this.if_visible = false;
            this.if_showing = false;
            gsap.timeline()
                .to(this._mms_selection, {
                    transform: "translateX(-100%)",
                    duration: 0,
                })
                .to(this._menubox_menu_backg_img, {
                    transform: "translateY(100%)",
                    duration: 0,
                })
                .to(this._menubox_menu, {
                    transform: "translateY(100%)",
                    "border-top-left-radius": "10rem",
                    "border-top-right-radius": "10rem",
                    duration: 0,
                });
        },
        check() {
            if (!this.if_showing) {
                this._menubox_navigation_menu.classList.toggle(
                    "menubox_navigation_menu_show"
                );
                if (this.if_visible) this.hidden();
                else this.show();
            }
        },
    },
    mounted() {
        this._menubox_menu = document.querySelector(".menubox_menu");
        this._mms_selection = document.querySelectorAll(".mms_selection");
        this._menubox_navigation_menu = document.querySelector(
            ".menubox_navigation_menu"
        );
        this._menubox_menu_backg_img = document.querySelectorAll(
            ".menubox_menu_backg img"
        );
    },
};
</script>

<style>
#menubox {
    position: fixed;
    z-index: 100000000;
}
.menubox_logo {
    position: fixed;
    justify-content: center;
    align-items: center;
    top: 2rem;
    left: 1.5rem;
    width: 4.5rem;
    height: 4.5rem;
    cursor: pointer;
    z-index: 1000000;
    mix-blend-mode: difference;
}

.menubox_logo img {
    position: relative;
    width: 100%;
    transition: 0.3s ease;
}

.menubox_logo:hover img {
    transform: rotate(15deg);
}

.menubox_navigation {
    position: fixed;
    justify-content: center;
    align-items: center;
    top: 2rem;
    right: 1.5rem;
    z-index: 1000000;
    mix-blend-mode: difference;
}

.menubox_navigation_menu {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 2rem;
    cursor: pointer;
    transition: 0.3s ease;
}

.menubox_navigation_menu line,
.menubox_navigation_menu polyline {
    stroke: var(--theme_white);
    stroke-width: 7;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.menubox_navigation_menu line {
    transition: 0.3s ease;
}

.menubox_navigation_menu polyline {
    stroke-dasharray: 59.5;
    stroke-dashoffset: -59.5;
    transition: 0.5s ease;
}

.menubox_navigation_menu:hover {
    transform: translateX(10%);
}

.menubox_navigation_menu line:nth-child(2) {
    transition-delay: 0.1s;
}

.menubox_navigation_menu line:nth-child(3) {
    transition-delay: 0.15s;
}

.menubox_navigation_menu line:nth-child(4) {
    transition-delay: 0.2s;
}

.menubox_navigation_menu_show line:nth-child(1),
.menubox_navigation_menu_show line:nth-child(2),
.menubox_navigation_menu_show line:nth-child(3) {
    transform: translateX(100%);
}

.menubox_navigation_menu_show line:nth-child(4) {
    transform: translateX(-100%);
}

.menubox_navigation_menu_show polyline {
    stroke-dashoffset: 0;
}

.menubox_navigation_contact {
    position: relative;
    justify-content: center;
    align-items: center;
    width: 9.5rem;
    height: 2.5rem;
    border: 0.2rem solid var(--theme_white);
    border-radius: 1.5rem;
    cursor: pointer;
    overflow: hidden;
}

.menubox_navigation_contact p {
    font-family: eng;
    font-size: 1.8rem;
    color: var(--theme_white);
    letter-spacing: 0.08rem;
    text-transform: uppercase;
    transition: 0.3s ease;
    z-index: 1;
}

.menubox_navigation_contact div {
    position: absolute;
    top: 0;
    left: 0;
    width: 9.5rem;
    height: 2.5rem;
    background-color: var(--theme_white);
    border-radius: 1.5rem;
    z-index: 0;
    transform: translateY(100%);
    transition: 0.3s ease;
}

.menubox_navigation_contact:hover div {
    transform: translateY(0);
}

.menubox_navigation_contact:hover p {
    color: var(--theme_black);
}

.menubox_menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: var(--theme_white);
    transform: translateY(100%);
    border-top-left-radius: 10rem;
    border-top-right-radius: 10rem;
    z-index: 100000;
}

.menubox_menu_backg {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 65rem;
    height: 45rem;
    overflow: hidden;
}

.menubox_menu_backg img {
    transform: translateY(100%);
}

.menubox_menu_backg img:nth-child(1) {
    position: absolute;
    top: 12rem;
    left: 15rem;
    width: 28rem;
}

.menubox_menu_backg img:nth-child(2) {
    position: absolute;
    top: 0rem;
    right: -2rem;
    width: 30rem;
}

.menubox_menu_backg img:nth-child(3) {
    position: absolute;
    top: 32rem;
    left: 7rem;
    width: 25rem;
}

.menubox_menu_backg img:nth-child(4) {
    position: absolute;
    bottom: -45rem;
    right: -5rem;
    width: 48rem;
}

.menubox_menu_selections {
    flex-direction: column;
    margin-top: 5rem;
    margin-left: 20rem;
    overflow: hidden;
}

.mms_selection {
    flex-direction: column;
    margin-bottom: 2.5rem;
    cursor: pointer;
    transform: translateX(-100%);
}

.mms_selection p:nth-child(1) {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: eng;
    font-size: 4rem;
    color: var(--theme_black);
    line-height: 4rem;
    letter-spacing: 0.2rem;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
}

.mms_selection p:nth-child(1)::before {
    content: "";
    position: absolute;
    width: 0;
    height: 1rem;
    background-color: var(--theme_green);
    transition: 0.2s ease;
}

.mms_selection p:nth-child(2) {
    font-family: zh;
    font-size: 2.2rem;
    color: var(--theme_black);
    line-height: 2.2rem;
    letter-spacing: 0.5rem;
    transition: 0.2s ease;
}

.mms_selection:hover p:nth-child(1)::before {
    width: 100%;
}

.mms_selection:hover p:nth-child(2) {
    color: var(--theme_green);
}

@media screen and (max-aspect-ratio: 1.5/1) {
    .menubox_menu {
        justify-content: center;
        align-items: center;
    }

    .menubox_menu_selections {
        margin-top: 0rem;
        margin-left: 0rem;
    }

    .mms_selection p:nth-child(1) {
        font-size: 6rem;
        line-height: 6rem;
        letter-spacing: 0.3rem;
        margin-bottom: 0.75rem;
    }

    .mms_selection p:nth-child(1)::before {
        height: 1.5rem;
    }

    .mms_selection p:nth-child(2) {
        font-size: 3.3rem;
        line-height: 3.3rem;
        letter-spacing: 0.75rem;
    }
}
</style>

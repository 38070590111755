import Vue from 'vue'
import VueRouter from 'vue-router';

Vue.use(VueRouter)
const router = new VueRouter({
    mode: "history",
    routes: [
        {
            path: '/',
            redirect: '/home'
        },
        {
            path: '/home',
            name: 'home',
            component: () => import('../views/home.vue'),
        },
        {
            path: '/videos',
            name: 'videos',
            component: () => import('../views/videos.vue'),
        },
        {
            path: '/photos',
            name: 'photos',
            component: () => import('../views/photos.vue'),
        },
        {
            path: '/contact',
            name: 'contact',
            component: () => import('../views/contact.vue'),
        },
        {
            name: '404',
            path: '/404',
            component: () => import('../views/404.vue')
        },
        {
            path: '/:pathMatch(.*)*',
            redirect: '/404'
        }
    ]
});
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
//push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject)
        return originalPush.call(this, location, onResolve, onReject);
    return originalPush.call(this, location).catch(err => err);
};
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
    if (onResolve || onReject)
        return originalReplace.call(this, location, onResolve, onReject);
    return originalReplace.call(this, location).catch(err => err);
};
export default router;


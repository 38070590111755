<template>
    <div id="app">
        <Loading ref="loading" />
        <Menu ref="menu" />
        <router-view></router-view>
    </div>
</template>

<script>
import Loading from "./components/loading.vue";
import Menu from "./components/menu.vue";

export default {
    name: "App",
    components: {
        Loading,
        Menu,
    },
    data() {
        return {
            // 页面是否加载完成
            if_loadover: false,
            // 是否为手机端
            if_pmd: false,
        };
    },
    methods: {
        // 检查登录状态
        check_loading() {
            let timer = setInterval(() => {
                if (document.readyState === "complete") {
                    this.if_loadover = true;
                    clearInterval(timer);
                }
            }, 300);
        },
    },
    mounted() {
        this.$router.beforeEach((to, from, next) => {
            this.if_loadover = false;
            this.$refs.loading.show(next);
        });
        if (
            navigator.userAgent.indexOf("iPhone") != -1 ||
            navigator.userAgent.indexOf("iPad") != -1 ||
            navigator.userAgent.indexOf("iPod") != -1 ||
            navigator.userAgent.indexOf("Android") != -1
        ) {
            this.if_pmd = true;
        }
        this.check_loading();
    },
};
</script>

<style>
* {
    margin: 0;
    padding: 0;
    font-size: 2vmin;
    --theme_black: #171717;
    --theme_white: #f7f7f7;
    --theme_green: #17f700;
}

p {
    user-select: none;
}

img {
    pointer-events: none;
    user-select: none;
}

div {
    display: flex;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

::-webkit-scrollbar {
    display: none;
}

::selection {
    background-color: var(--theme_green);
    color: var(--theme_black);
}

video::-webkit-media-controls {
    display: none !important;
}

body {
    width: 100%;
    background-color: var(--theme_black);
}

@media screen and (max-aspect-ratio: 1.5/1) {
    * {
        font-size: 1vmax;
    }
}

@font-face {
    font-family: eng;
    src: url(~@/assets/font/impact.ttf);
}

@font-face {
    font-family: zh;
    src: url("https://cdn.jiejoe.com/fonts/%E6%80%9D%E6%BA%90%20Medium.otf");
}
</style>

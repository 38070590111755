<template>
    <div id="loading" v-show="if_visable">
        <div class="loading_greenblock"></div>
        <div class="loading_blackblock"></div>
        <div class="loading_icon">
            <div class="loading_icon_cavas"></div>
            <p class="loading_icon_tiptext">loading...</p>
        </div>
    </div>
</template>

<script>
import lottie from "lottie-web";
import gsap from "gsap";
export default {
    name: "loading",
    data() {
        return {
            an_data: require("../assets/json/animations/loading.json"),
            an_player: {},
            if_visable: true,
            shower: 0,
        };
    },
    methods: {
        hidden() {
            this.an_player.pause();
            if (this.shower) this.shower.kill();
            this.shower = gsap
                .timeline()
                .to(".loading_icon", {
                    opacity: 0,
                    duration: 0.8,
                    ease: "power4.out",
                })
                .to(
                    ".loading_blackblock",
                    {
                        x: "100%",
                        "border-radius": "3rem",
                        duration: 1.3,
                        ease: "power4.out",
                        onComplete: () => {
                            this.if_visable = false;
                        },
                    },
                    "<0.3"
                )
                .to(
                    ".loading_greenblock",
                    {
                        x: "100%",
                        "border-radius": "3rem",
                        duration: 1.3,
                        delay: 0.3,
                        ease: "power4.out",
                    },
                    "<"
                );
        },
        show(next) {
            this.if_visable = true;
            this.an_player.goToAndPlay(0, true);
            if (this.shower) this.shower.kill();
            this.shower = gsap
                .timeline()
                .to(".loading_greenblock", {
                    x: 0,
                    "border-radius": 0,
                    duration: 1.3,
                    ease: "power4.out",
                })
                .to(
                    ".loading_blackblock",
                    {
                        x: 0,
                        "border-radius": 0,
                        duration: 1.3,
                        delay: 0.3,
                        ease: "power4.out",
                        onComplete: () => {
                            next();
                            this.$parent.check_loading();
                            if (this.$parent.$refs.menu.if_visible)
                                this.$parent.$refs.menu.reload();
                        },
                    },
                    "<"
                )
                .to(
                    ".loading_icon",
                    {
                        opacity: 1,
                        duration: 0.8,
                        ease: "power4.out",
                    },
                    "<0.3"
                );
        },
    },
    mounted() {
        this.an_player = lottie.loadAnimation({
            container: document.querySelector(".loading_icon_cavas"),
            renderer: "svg",
            loop: false,
            autoplay: true,
            animationData: this.an_data,
        });
        this.an_player.addEventListener("enterFrame", () => {
            //动画已完成过渡变化
            if (this.an_player.currentFrame >= 181) {
                // 页面是否加载完毕，否则再次播放动画
                if (this.$parent.if_loadover) {
                    // 页面加载完成，需要等到动画全部播放完才进入页面
                    if (this.an_player.currentFrame > 260) {
                        this.hidden();
                        sessionStorage.setItem("if_entered", true);
                    }
                } else this.an_player.goToAndPlay(0, true);
            }
            // 若不为第一次进入，且页面已经加载完毕
            if (
                sessionStorage.getItem("if_entered") &&
                this.$parent.if_loadover
            ) {
                this.hidden();
            }
        });
    },
};
</script>

<style>
#loading {
    position: fixed;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10000000000;
}
.loading_greenblock {
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--theme_green) url(~@/assets/svg/home/loading_block.svg)
        repeat;
    background-size: 30%;
}
.loading_blackblock {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--theme_black);
}
.loading_icon {
    flex-direction: column;
    align-items: center;
}
.loading_icon_cavas {
    width: 25rem;
}
.loading_icon_tiptext {
    font-family: eng;
    font-size: 1.5rem;
    color: var(--theme_green);
    letter-spacing: 0.2rem;
    text-transform: uppercase;
    transform: translateY(-6rem);
}
</style>
